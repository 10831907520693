<template>
  <div class="process-auction-registration">
    <fd-form @submit="submitForm">
      <modal-content class="card">
        <template #header>
          <h4 class="p-3">{{ toTitleCase(type) }} Auction Registration</h4>
        </template>
        <template #body>
          <div class="p-3 mb-2">
            <p>
              Are you sure you want to {{ type.toLowerCase() }} this auction
              registration? This action cannot be undone.
            </p>
            <p class="mb-3">Leave a remark for the registrant if necessary.</p>
            <div class="row">
              <fd-textarea
                v-model="remark"
                class="col-12"
                label="Remark"
                name="auctionRemark"
                type="text"
              ></fd-textarea>
            </div>
          </div>
        </template>

        <template #footer>
          <div class="d-flex justify-content-end p-2">
            <fd-button @click="$emit('close')">Close</fd-button>
            <fd-button
              type="submit"
              class="ml-2"
              :class="{ danger: type === 'Reject', main: type === 'Approve' }"
            >
              {{ toTitleCase(type) }}
            </fd-button>
          </div>
        </template>
      </modal-content>
    </fd-form>
  </div>
</template>

<script>
import { toTitleCase } from "@/utils/string";

export default {
  components: {
    ModalContent: () =>
      import("@/components/GlobalComponents/ModalBox/ModalContent")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      default: "Reject",
      validator: (val) => ["Approve", "Reject"].includes(val)
    }
  },
  data: function () {
    return {
      remark: "",
      toTitleCase
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    submitForm() {
      switch (this.type) {
        case "Approve":
          this.$emit("approve", { approveRemark: this.remark });
          break;
        case "Reject":
          this.$emit("reject", { rejectRemark: this.remark });
          break;
        default:
          this.$emit("submit", { remark: this.remark });
          break;
      }
    }
  }
};
</script>

<style lang="scss"></style>
